@media screen and (max-width: 720px) {
    .p-datatable .p-datatable-tbody > tr > td {
        padding: 0 !important;
        /* text-align: center !important; */
    }
    .p-datatable .p-datatable-tbody > tr > td:nth-child(3) {
        text-align: center;
    }
    .p-datatable .p-datatable-tbody > tr > td:nth-child(2) {
        text-align: center;
    }
    .p-datatable .p-datatable-tbody > tr > td:nth-child(1) {
        text-align: center;
    }

    .p-datatable .p-datatable-thead > tr > th {
        padding: 0 !important;
    }

    .p-datatable .p-column-header-content {
        justify-content: center;
    }
    
    .p-datatable-tbody {
        font-size: 12px;
    }

    .headerContent {
        height: 50px;
    }

    .headerSpace {
        height: 50px !important;
    }

    .headerBtn{
        padding: 0;
        width: 100px;
    }

    .datatable-rowexpansion-demo .orders-subtable{
        padding:0 !important;
    }

    .p-datatable-wrapper {
        display: inline-block;
        overflow: auto;
        width: 100vw;
    }
}

.headerSpace {
    height: 75px;
}


.datatable-rowexpansion-demo .product-image {
    width: 100px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.datatable-rowexpansion-demo .orders-subtable {
    padding: 1rem;
}


.ms{
    background: #fff;
    padding: 6px 15px 6px 15px;
    border-radius: 4px;
    color: black;
    /* border: 1px solid #cdcdcd; */
}

.te{
    background: #48a32a;
    padding:  6px 15px 6px 15px;
    border-radius: 4px;
    color: white;
    border: 1px solid #cdcdcd;
}

.ted {
    background: #f44336;
    padding:  6px 15px 6px 15px;
    border-radius: 4px;
    color: white;
    border: 1px solid #cdcdcd;
}

.ze {
    background: #f4f136;
    padding:  6px 15px 6px 15px;    
    border-radius: 4px;
    color: black;
    border: 1px solid #cdcdcd;
}

.truck {
    width: 33px;
    height: 44px;
    -webkit-mask: url(../img/truck.svg) no-repeat center;
    mask: url(../img/truck.svg) no-repeat center;
  }

  .truckContent {
      display: flex;
      align-items: center;
  }

  .truckText{
      margin-left: 10px;
  }


  .ds-green {
    border-radius: 8px;
    padding: 0px 12px 0px 4px;
    border: 1px solid black;
    background-color: green;
  }

  .ds-yellow {
    border-radius: 8px;
    padding: 0px 12px 0px 4px;
    border: 1px solid black;
    background-color: #ffb500;
  }

  .ds-red {
    border-radius: 8px;
    padding: 0px 12px 0px 4px;
    border: 1px solid black;
    background-color: red;
  }


  .ratioSpan {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .red {
      color: red
  }

  .p-datatable-thead {
      font-size: 12px;
  }

  .p-datatable-thead tr th:nth-child(2) div span {
    width: 50px;
  }

  .p-datatable-thead tr th:nth-child(3) div span {
    width: 55px;
  }

  .p-datatable-thead tr th:nth-child(4) div span {
    width: 41px;
  }

  .p-datatable-thead tr th:nth-child(5) div span {
    width: 45px;
  }

  .p-datatable-thead tr th:nth-child(6) div span {
    width: 37px;
  }
  
  .p-datatable-thead tr th:nth-child(7) div span {
    width: 37px;
  }

  .dl-danger {
      background: rgb(220, 53, 69) !important;
  }

  .dl-warning {
    background: rgb(255, 193, 7) !important;
  }

  .dl-success {
    background: rgb(40, 167, 69) !important;
  }

  .orders-subtable table tr th:nth-child(1)  div span{
      /* width: 90px; */
      width: 55px;
      text-align: center;
  }

  .orders-subtable table tr th:nth-child(2)  div span{
    width: 80px;
    text-align: center;
}

.orders-subtable table tr th:nth-child(3)  div span{
    /* width: 190px; */
    width: 77px;
    text-align: center;
}


.orders-subtable table tr th:nth-child(4)  div span{
    width: 400px;
    /* text-align: center; */
}

.orders-subtable table tr th:nth-child(5)  div span{
    width: 500px;
    /* text-align: center; */
}

.orders-subtable table tr{
    height: 45px;
}


.footerFixed{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #1e4074;
    height: 45px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}


.footerFixed1{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #1e4074;
    height: 45px;
    color: white;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}


.zs {
    /* background: white;
    color: black;
    padding: 5px;
    border-radius: 5px;
    width: 50px;
    margin-left: 5px;
    font-size: 12px; */
    /* background: white; */
    
    color: white;
    border-radius: 5px;
    width: 43px;
    margin-left: 5px;
    font-size: 14px; 
}

.vehicleTotal {
    position: absolute;
    left: 29px;
    display: flex
}

.totalOther {
    display: flex;
    justify-content: flex-end;
    width: 42%;
    margin-left: 158px;
}

.vehicleTotalText{
    position: absolute;
    color: black;
    top: 7px;
    left: 4px;
}

/* .p-datatable-tbody {
    max-height: 100vh;
    overflow: auto;
    display: table-caption;
    table-layout: fixed;
} */


.p-datatable-thead {
    position: sticky;
    top: 0;
    z-index: 1;
}

.p-datatable-wrapper {
    overflow-y: auto;
    height: calc(100vh - 105px);
    /* height: 400px; */
}


.fontZs {
    font-weight: bold;
}


.p-datatable-table thead tr th:nth-child(4) div  {
    border-bottom: 2px solid rgb(20, 112, 250)
}

.orders-subtable .p-datatable-table thead tr th:nth-child(4) div  {
    border-bottom: none
}


.p-datatable-table thead tr th:nth-child(5) div  {
    border-bottom: 2px solid rgb(19, 223, 12)
}

.orders-subtable .p-datatable-table thead tr th:nth-child(5) div  {
    border-bottom: none
}


.p-datatable-table thead tr th:nth-child(6) div  {
    border-bottom: 2px solid red
}

.orders-subtable .p-datatable-table thead tr th:nth-child(6) div  {
    border-bottom: none
}

.p-datatable-table thead tr th:nth-child(7) div  {
    border-bottom: 2px solid orange
}

.orders-subtable .p-datatable-table thead tr th:nth-child(7) div  {
    border-bottom: none
}