.leaflet-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 75px)
}

.green {
    fill: darkgreen;
  }

  .leaflet-marker-icon {
      border: none;
      background: none;
  }