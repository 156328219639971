.headerContent{
    background-color: #094074;
    display: flex;
    width: 100%;
    height: 75px;
    align-items: center;
    justify-content: center;

    left: 0px;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1000;
}

.headerBtn{
    width: 120px;
    margin-left: 5px;
}

/* body {
    overflow: hidden;
} */
